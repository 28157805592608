import React from "react";
import '../App.css';

function Home() {

  return (
    <div>
      <h3>Home</h3>
    </div>
  );
}

export default Home;