import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Home, RingOne, RingTwo } from "./components";
function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/RingOne" exact component={() => <RingOne />} />
          <Route path="/RingTwo" exact component={() => <RingTwo />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;