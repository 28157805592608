import React, { useState } from "react";
import '../App.css';

function RingTwo() {

  const [color, setColor] = useState('silver');
  const [shape, setShape] = useState('round');

  return (
    <div className="mainContainer">
      <img class="ringImage" src={`https://form.diamondstormdesigns.com/emma-${color}-${shape}.png`} alt="Diamond Storm Ring" />
      <h3>Color 2</h3>
      <button onClick={() => setColor('silver')}>Silver</button>
      <button onClick={() => setColor('gold')}>Gold</button>

      <h3>Shape 2</h3>
      <button onClick={() => setShape('round')}>Round</button>
      <button onClick={() => setShape('oval')}>Oval</button>
    </div>
  );
}

export default RingTwo;
