import React, { useState } from "react";
import '../App.css';
import logo from '../assets/images/Diamond-Storm-Logo.png';
import backIcon from '../assets/images/Back-Header.png';

function RingOne() {
 
   const [color, setColor] = useState('silver');
   const [shape, setShape] = useState('round');

  return (
    <div className="mainContainer">
      {/* HEADER */}
      <div className="headerContainer">
        <img src={logo} className="headerLogo" alt="Header Logo"/>
        <img src={backIcon} className="backIcon" alt="Back Icon"/>
      </div>
      {/* BODY CONTENT */}
      <div className="customiserContainer">
        <div className="previewContainer">
        <img class="ringImage" src={`https://form.diamondstormdesigns.com/emma-${color}-${shape}.png`} alt="Diamond Storm Ring" />
        </div>
        <div className="optionsContainer">
          <h3>Color 1</h3>
          <button onClick={() => setColor('silver')}>Silver</button>
          <button onClick={() => setColor('gold')}>Gold</button>

          <h3>Shape 1</h3>
          <button onClick={() => setShape('round')}>Round</button>
          <button onClick={() => setShape('oval')}>Oval</button>
        </div>
      </div>
      {/* BOTTOM BAR */}
      <div className="bottomContainer">
        <h3 className="bottomText">Happy with your design?</h3>
        <a className="sendEnquiry" href={`mailto:hello@diamondstormdesigns.com?subject=Custom Ring Enquiry&body=Hi I am enquiring about the ring I just designed: %0D%0Ahttps://form.diamondstormdesigns.com/emma-${color}-${shape}.png %0D%0A%0D%0ARegards,%0D%0A%0D%0A{Your Name}`}>Send Enquiry</a>
      </div>
    </div>
  );
}

export default RingOne;
